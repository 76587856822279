<template>
  <b-dropdown aria-role="list" position="is-top-left">
    <template #trigger="{ active }">
      <b-button
        :label="locales[locale]"
        type="is-text"
        :icon-right="active ? 'menu-down' : 'menu-up'" />
    </template>

    <b-dropdown-item aria-role="listitem" v-for="(name, key) in locales" :key="key" @click="switchLocale(key)">{{ name }}</b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { availableLanguages, loadLanguageAsync } from '@/i18n'

export default {
  computed: {
    locale () {
      return this.$root.$i18n.locale
    },

    locales () {
      return availableLanguages
    }
  },

  methods: {
    switchLocale (locale) {
      loadLanguageAsync(locale)
    }
  }
}
</script>
