<template>
  <main class="virtualsomm-landing">
    <div class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="virtualsomm-landing-taglines">
            <h1>
              {{ $t('VirtualSomm') }} &middot; {{ $t('A sommelier in your pocket')}}
            </h1>
            <p class="tagline">{{ $t('The wine shelves will be your best friends!') }}</p>
            <i18n path="VirtualSomm is a personalized wine recommendation app which shows you {bold_part}." tag="p">
              <template #bold_part>
                <strong>{{ $t('the wine you need when you need it') }}</strong>
              </template>
            </i18n>
            <i18n path="Your taste matters! Get a recommendation based on {bold_part}, and buy it in your supermarket." tag="p">
              <template #bold_part>
                <strong>{{ $t('your needs and your taste') }}</strong>
              </template>
            </i18n>
          </div>
        </div>
      </div>
      <div class="hero-foot">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none" class="wave-under-hero-body">
          <defs>
            <linearGradient id="wave-gradient">
              <stop offset="0%" stop-color="hsl(218, 39%, 23%)" />
              <stop offset="40%" stop-color="hsl(218, 39%, 23%)" />
              <stop offset="100%" stop-color="hsl(321, 46%, 29%)" />
            </linearGradient>
          </defs>
          <path fill="url(#wave-gradient)" fill-opacity="1" d="M0,128L40,117.3C80,107,160,85,240,101.3C320,117,400,171,480,176C560,181,640,139,720,101.3C800,64,880,32,960,58.7C1040,85,1120,171,1200,224C1280,277,1360,299,1400,309.3L1440,320L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
        </svg>
        <div class="container">
          <div class="level">
            <div class="level-left virtualsomm-landing-email">
              <div class="field">
                <label class="label" for="virtualsomm-collect-m">
                  <strong>{{ $t('Be the first informed!') }}</strong>
                  {{ $t('Our app will be ready soon.') }}
                </label>
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <input class="input is-medium" type="email" id="virtualsomm-collect-m" :placeholder="$t('Enter your email address…')" v-model="email" @keyup.enter.prevent="store_email" />
                  </div>
                  <div class="control">
                    <button class="button is-medium is-primary" :class="{'is-loading': loading}" :aria-label="$t('Subscribe to VirtualSomm updates')" @click="store_email">
                      <b-icon icon="chevron-right" size="is-medium"/>
                    </button>
                  </div>
                </div>
                <p class="help">{{ $t('We will send you occasional emails when VirtualSomm is ready. We will not spam you.') }}</p>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item virtualsomm-landing-locale">
                <phone-preview :button="$t('What\'s the best wine?')" class="virtualsomm-landing-preview" />
                <locale-switcher class="virtualsomm-landing-locale-dropdown" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="virtualsomm-landing-mobile-logo">
      <virtual-somm-logo />
    </footer>
  </main>
</template>

<script>
import PhonePreview from '@/components/PhonePreview'
import VirtualSommLogo from '@/components/VirtualSommLogo'
import LocaleSwitcher from '@/components/LocaleSwitcher'

import { DialogProgrammatic as Dialog, ToastProgrammatic as Toast } from 'buefy'

export default {
  components: {
    PhonePreview,
    VirtualSommLogo,
    LocaleSwitcher
  },

  data () {
    return {
      email: '',
      loading: false
    }
  },

  methods: {
    store_email () {
      if (this.loading || !this.email) return
      if (this.email.indexOf('@') === -1) {
        Toast.open({
          message: this.$t('Please enter an e-mail.'),
          position: 'is-bottom'
        })
        return
      }

      let api

      if (process.env.NODE_ENV === 'development') {
        api = 'http://127.0.0.1:8001/email.php'
      } else {
        api = '/email.php'
      }

      this.loading = true

      const data = new URLSearchParams()
      data.append('email', this.email)
      data.append('locale', this.$i18n.locale)

      fetch(api, {
        method: 'POST',
        body: data
      }).then(() => {
        this.loading = false
        Dialog.alert({
          message: this.$t('Thank you for sharing your email address! We will send you a notification as soon as the app is ready!'),
          confirmText: this.$t('Close')
        })
        this.email = ''
      }).catch(() => {
        this.loading = false
        Toast.open({
          message: this.$t('There was an error while saving your email address. Please retry.'),
          position: 'is-bottom'
        })
      })
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/virtualsomm"
@import "~bulma/sass/utilities/mixins"
@import "~animate.css/animate.css"

main.virtualsomm-landing
  position: relative
  background: linear-gradient(90deg, var(--primary-blue) 40%, var(--primary-purple) 100%), var(--primary-blue)

.virtualsomm-landing-preview
  position: absolute
  right: 0
  top: -75vh
  height: 76vh
  max-width: 28rem
  cursor: default
  user-select: none
  filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.33))

  +touch
    display: none

  +until-fullhd
    right: 1vw

  +until-widescreen
    top: -70vh
    right: 0

.virtualsomm-landing-taglines
  width: 70%

  +until-widescreen
    width: 67%

  +touch
    width: 100%

  h1
    margin-bottom: 0

    font-variant: all-small-caps
    letter-spacing: .4rem
    font-size: 1.6rem
    font-weight: 500

    animation: fadeInUp
    animation-duration: 1s

    +until-widescreen
      font-size: 1.4rem

    +touch
      margin-bottom: 1rem

    color: var(--primary-beige)

  p
    margin-top: 1rem
    font-size: 1.6rem

    +until-widescreen
      font-size: 1.4rem

    +touch
      font-size: 1.3rem

    animation: fadeInUp
    animation-duration: 2s

    &, strong
      color: var(--primary-beige)

    &:not(:last-child)
      margin-top: 2.2rem

  p.tagline
    margin-top: 0

    font-family: "EB Garamond", serif
    font-size: 3.6rem
    line-height: 1

    +until-widescreen
      font-size: 3.2rem

    +touch
      font-size: 2.8rem

    color: var(--white)

    animation: fadeInUp
    animation-duration: 1.5s

.hero-foot
  position: relative
  padding-top: 14rem

  +until-fullhd
    padding-top: 6rem

  background-color: var(--primary-grey)
  user-select: none

  .wave-under-hero-body
    position: absolute
    top: 0
    right: 0
    left: 0

    width: 100%
    height: 16rem

    +until-fullhd
      height: 6rem

    +touch
      height: 4rem

  .level
    +touch
      margin: 0 1rem

  %animate-footer
    animation: fadeIn
    animation-duration: 2s

  .virtualsomm-landing-email
    width: 50%
    margin-bottom: 4rem

    @extend %animate-footer

    +until-fullhd
      margin-bottom: 1.5rem

    +touch
      width: auto

    label.label
      font-weight: normal
      font-size: 1.4rem

    input
      background-color: hsl(210, 16%, 82%)

    ::placeholder
      color: hsl(209, 18%, 30%)

.virtualsomm-landing-locale
  .virtualsomm-landing-locale-dropdown
    @extend %animate-footer

  button.is-text
    text-decoration: none

.virtualsomm-landing-mobile-logo
  +desktop
    display: none

  padding-top: 10rem
  padding-bottom: 10rem

  background: var(--primary-grey)

  text-align: center

  svg
    margin: 0 1rem
    max-width: 12rem

    path
      fill: var(--primary-blue)
      transition: fill .6s ease-in-out

    &:hover path
      fill: var(--primary-purple)

</style>
