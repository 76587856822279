import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/../locales/en'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages // set locale messages
})

const loadedLanguages = ['en'] // our default language that is preloaded
export const availableLanguages = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch'
//  it: 'Italiano'
}

function setI18nLanguage (lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  localStorage.setItem('locale', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `@/../locales/${lang}.json`).then(
    messages => {
      i18n.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  ).catch(() => {
    // If the locale cannot be loaded, we try to load the less-specific locale
    // (i.e. if fr-FR fails, we try fr).

    // Nothing left to try: unavailable.
    if (!lang.includes('-')) return

    const locale_parts = lang.split('-')
    locale_parts.pop()

    return loadLanguageAsync(locale_parts.join('-'))
  })
}

export function loadLocaleForBrowser () {
  const storedLocale = localStorage.getItem('locale')
  if (storedLocale) loadLanguageAsync(storedLocale)
  else loadLanguageAsync(navigator.language)
}

export default i18n
